.payment-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 20px;
}

.logo-container {
  text-align: center;
  margin-bottom: 30px;
}

.brand-logo {
  max-width: 320px;
  height: auto;
}

.payment-container {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-and-summary {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.user-form {
  flex: 1;
  min-width: 300px;
}

.customer-info {
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.phone-email {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.phone-input-group {
  display: flex;
  gap: 10px;
  flex: 1;
  min-width: 200px;
}

.country-code-dropdown {
  width: auto;
  min-width: 120px;
}

.email {
  flex: 1;
  min-width: 200px;
}

.country-state {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}

.form-input1,
.form-input2 {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.tradingview-group {
  position: relative;
}

.help-link {
  position: absolute;
  right: 0;
  bottom: -25px;
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.plan-selection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin: 20px 0;
}

.plan-btn {
  padding: 15px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.plan-btn.active {
  border-color: #007bff;
  background: #007bff;
  color: white;
}

.terms-conditions {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin: 20px 0;
  font-size: 14px;
}

.terms-conditions input[type="checkbox"] {
  margin-top: 3px;
}

.terms-conditions a {
  color: #007bff;
  text-decoration: none;
}

.terms-conditions a:hover {
  text-decoration: underline;
}

.form-submit-btn {
  width: 100%;
  padding: 15px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.form-submit-btn:hover {
  background: #0056b3;
}

.order-summary {
  flex: 0 0 300px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 10px;
  position: relative;
}

.badge {
  position: absolute;
  top: -10px;
  right: 20px;
  background: #007bff;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
}

.summary-table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
}

.summary-table td {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.summary-table tr:last-child td {
  border-bottom: none;
}

.total-section {
  margin-top: 30px;
  text-align: center;
}

.total-section h2 {
  color: #007bff;
  margin-bottom: 10px;
}

.success-message {
  text-align: center;
  padding: 40px;
}

.dashboard-button {
  margin-top: 20px;
  padding: 15px 30px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.footer {
  text-align: center;
  padding: 20px;
  margin-top: 40px;
  font-size: 14px;
  color: #666;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Video Modal Styles */
.video-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.video-modal {
  position: relative;
  width: 90%;
  max-width: 800px;
  background: white;
  border-radius: 10px;
  padding: 20px;
}

.close-modal {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .payment-container {
    padding: 10px;
  }

  .form-and-summary {
    flex-direction: column;
  }

  .order-summary {
    flex: 1;
    margin-top: 30px;
  }

  .phone-email {
    flex-direction: column;
  }

  .phone-input-group,
  .email {
    width: 100%;
  }

  .country-state {
    grid-template-columns: 1fr;
  }

  .close-modal {
    top: 10px;
    right: 10px;
    color: #333;
  }

  .video-modal {
    width: 95%;
    margin: 20px;
  }

  .brand-logo {
    max-width: 250px;
  }
}

@media (max-width: 480px) {
  .payment-page {
    padding: 10px;
  }

  .customer-info {
    padding: 10px;
  }

  .form-input,
  .form-input1,
  .form-input2 {
    padding: 10px;
    font-size: 14px;
  }

  .plan-selection {
    grid-template-columns: 1fr;
  }

  .terms-conditions {
    font-size: 12px;
  }
}


.video-modal {
  position: relative;
  width: 90%;
  max-width: 800px;
  background: white;
  border-radius: 10px;
  padding: 20px;
}

.close-modal {
  position: absolute;
  top: -15px;
  right: -15px;
  background: white;
  border: none;
  color: #333;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  transition: all 0.3s ease;
}

.close-modal:hover {
  background: #f0f0f0;
  transform: scale(1.1);
}

/* Adjust for mobile */
@media (max-width: 768px) {
  .close-modal {
    top: 5px;
    right: 5px;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
}