/* src/SuccessPage.css */
.success-page {
  text-align: center;
  padding: 20px;
  margin-top: 50px;
}

.success-page h2 {
  color: #f44336; /* Red color for failed payment */
}

.success-page p {
  font-size: 1.2rem;
}

.dashboard-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.1rem;
  color: #fff;
  background-color: #f44336; /* Red background for failure */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard-button:hover {
  background-color: #e53935;
}
