/* Global Styles */
body {
    font-family: 'Poppins', sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
    color: #333;
}

/* Hero Section */
.hero-section {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    background-color: #fff;
}

.hero-content {
    max-width: 50%;
}

.hero-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.hero-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #666;
}

.btn-primary {
    background-color: #000;
    color: #fff;
    padding: 12px 30px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.1rem;
    cursor: pointer;
    border: none;
}

.features {
    margin-top: 20px;
    color: #666;
    font-size: 0.9rem;
}

.features span {
    margin-right: 10px;
}

/* Images in Hero Section */
.hero-images {
    display: flex;
    gap: 20px;
}

.furniture-img,
.chair-img {
    width: 200px;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
}

/* Products Section */
.products-section {
    padding: 50px;
    background-color: #f9f9f9;
    text-align: center;
}

.products-section h2 {
    font-size: 2rem;
    margin-bottom: 30px;
}

.product-list {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.product-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 150px;
}

.product-item img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.product-item p {
    font-size: 1.1rem;
    color: #333;
}

/* Footer */
/* .footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-links {
    margin-bottom: 20px;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    font-size: 0.9rem;
  }
  
  .footer p {
    font-size: 0.8rem;
    color: #bbb;
  } */