/* SuccessPage.css */

/* Container for centering the content */
.success-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

/* Main content container */
.container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
}

/* Heading style */
.success-header {
  font-size: 2rem;
  font-weight: bold;
  color: #4caf50;
  text-align: center;
  margin-bottom: 20px;
}

/* Success message */
.success-message {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Email message */
.email-message {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 30px;
  color: #555;
}

/* Tutorial Section */
.tutorial-section {
  margin-bottom: 30px;
}

.tutorial-section h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.tutorial-section p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
}

/* Video container */
.video-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

/* Loading spinner or message */
.loading {
  font-size: 1.2rem;
  color: #888;
  text-align: center;
}

/* Button container */
.button-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

/* Button styling */
.dashboard-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 12px 25px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 48%;
  text-align: center;
}

.dashboard-button:hover {
  background-color: #45a049;
}

.dashboard-button:focus {
  outline: none;
}