/* src/App.css */

/* Use Best Font -Family for user experience  */

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.payment-page {
  background-color: rgb(237, 237, 250);
  /* background-color: #f5f7fa; */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

/* Brand Logo */
.logo-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.brand-logo {
  width: 150px;
  height: auto;
}

/* Payment Container */
.payment-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  width: 100%;
  gap: 30px;
  box-sizing: border-box;
}

/* start here */

.customer-info {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0px;
}

.customer-info h3 {
  font-weight: 700;
}

.order-summary {
  width: 40%;
  color: white;
  height: max-content;
  font-weight: bold;
  background-color: #7427c0;
  padding: 25px;
  border-radius: 15px;
  border: none;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  transition: box-shadow 0.3s ease;
}

.order-summary:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.order-summary h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: center;
  border-bottom: 1px solid #ffffff50;
  padding-bottom: 10px;
}

/* Table formatting for order details */
.summary-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  border-bottom: 1px solid #ffffff50;
}

.summary-table tr {
  /* border-bottom: 1px solid #ffffff50; */
}

.summary-table td {
  padding: 10px 0;
  color: #f1f1f1;
  font-size: 1.1rem;
}

.summary-table td:first-child {
  font-weight: bold;
  text-align: left;
}

.summary-table td:last-child {
  text-align: right;
  font-weight: normal;
}

/* Total section styling */
.total-section {
  text-align: center;
  /* margin-top: 20px; */
}

.total-section h2 {
  color: #ffcd38;
  font-size: 2rem;
  margin: 15px 0;
}

.total-section p {
  font-size: 1rem;
  margin: 5px 0;
  color: #f1f1f1;
}

/* Badge styling */
.badge {
  position: absolute;
  top: 5px;
  right: -15px;
  background-color: #ffcd38;
  color: #7427c0;
  font-size: 0.85rem;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  letter-spacing: 1px;
  transform: rotate(45deg);
  /* Rotating the badge */
}




.form-group {
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.form-group1 {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  width: 100%;
}

.form-group1 .country-state .form-input1 {
  width: 30%;
}

.form-group1 .country-state .form-input2 {
  width: 20%;
}

.phone-input-group {
  display: flex;
  gap: 10px;
}

input[type="text"],
input[type="email"],
select {
  width: 100%;
  max-width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-size: 1rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

input[type="tel"] {
  width: 63%;
  max-width: 90%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-size: 1rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.country-code-dropdown {
  width: 25%;
  min-width: 100px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
.country-code-dropdown,
select:focus {
  outline: none;
  border-color: #7427c0;
  /* max-width: 90%; */
}

input::placeholder {
  color: #888;
}

input:required {
  border-color: #e0e0e0;
}

input:focus:invalid {
  border-color: #ff4d4d;
}

input:valid {
  border-color: #7427c0;
}

.plan-selection {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  flex-wrap: wrap;
}

.plan-btn {
  padding: 10px 22px;
  border: 2px solid #7427c0;
  background-color: transparent;
  border-radius: 10px;
  color: #7427c0;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.plan-btn.active,
.plan-btn:hover {
  background-color: #7427c0;
  color: white;
}

.form-submit-btn {
  background-color: #7427c0;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.form-submit-btn:hover {
  background-color: #3369d6;
}


/* end here  */

.card-element {
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 15px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-section {
  margin-top: 25px;
}

.success-message {
  text-align: center;
  padding: 25px;
  border: 2px solid #4CAF50;
  border-radius: 15px;
  background-color: #f9fff9;
  color: #4CAF50;
  font-family: Arial, sans-serif;
}

.success-message h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.success-message p {
  font-size: 1.2em;
}

.dashboard-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.1em;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dashboard-button:hover {
  background-color: #45a049;
}

/* Style for terms and conditions */
.terms-conditions {
  display: flex;
  align-items: center;
  /* Align everything to the left */
  gap: 5px;
  margin-bottom: 20px;
  /* flex-wrap: wrap; */
}

.terms-conditions input[type="checkbox"] {
  width: 20px;
  height: 20px;
  accent-color: #7427c0;
  /* Set checkbox color */
  margin-right: 10px;
  /* Ensure space between checkbox and label */
}

.terms-conditions label {
  font-size: 0.9rem;
  color: #333;
  line-height: 1.5;
  /* Maintain good readability */
}

.terms-conditions a {
  color: #7427c0;
  text-decoration: underline;
}

.terms-conditions a:hover {
  color: #3369d6;
}

/* Adjustments for medium screens */
@media (max-width: 1024px) {
  .terms-conditions {
    gap: 4px;
    /* Reduce gap for medium screens */
  }

  .terms-conditions label {
    font-size: 0.9rem;
    /* Slightly smaller font size */
  }
}

/* Adjustments for small screens */
@media (max-width: 768px) {
  .phone-email {
    display: flex;
    gap: 10px;
    flex-direction: column;

  }

  .phone-email .form-input {
    width: 100%;
  }

  .phone-email .email {
    width: 95%;
    max-width: 95%;
    gap: 10px;
    padding: 12px;
  }

  .form-group>.phone-input-group {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
  }

  .plan-selection {
    gap: 2px;
  }

  /* input[type="tel"] {
    width: 63%;
  }
  .country-code-dropdown {
    width: 25%;
    min-width: 100px;
  } */
  .terms-conditions {
    gap: 5px;
    /* Smaller gap for mobile devices */
    flex-direction: row;
    /* Keep items in a row for medium devices */
    align-items: flex-start;
    /* Left-align the checkbox and label */
  }

  .terms-conditions label {
    font-size: 0.85rem;
    /* Slightly smaller font size for mobile */
  }
}

/* Adjustments for very small screens */
@media (max-width: 480px) {
  .phone-email .email {
    width: 90%;
    max-width: 90%;
    gap: 10px;
    padding: 12px;
  }

  .plan-selection {
    gap: 2px;
  }

  .terms-conditions {
    /* flex-direction: column;  */
    /* Stack items vertically on small screens */
    align-items: flex-start;
    /* Left-align */
  }

  .terms-conditions input[type="checkbox"] {
    margin-bottom: 10px;
    /* Add spacing between checkbox and label on small screens */
  }

  .terms-conditions label {
    font-size: 0.85rem;
    /* Slightly reduce font size */
  }
}


/* Footer */
.footer {
  text-align: center;
  margin-top: 40px;
  font-size: 0.85rem;
  color: #888;
}

.footer a {
  color: #7427c0;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Toast notification customization */
.Toastify__toast--success {
  background-color: #4CAF50;
}

.Toastify__toast--error {
  background-color: #FF4D4D;
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .payment-container {
    flex-direction: column;
    padding: 20px;
  }

  .customer-info,
  .order-summary {
    width: 100%;
  }

  .order-summary {
    margin-top: 20px;
  }

  .form-submit-btn {
    width: 100%;
  }

  .plan-selection {
    flex-direction: column;
  }

  .plan-btn {
    width: 100%;
    text-align: center;
  }

  .form-group {
    gap: 5px;
    /* flex-direction: column; */
  }

  .phone-input-group {
    flex-direction: column;
    gap: 5px;
  }
}

@media (max-width: 480px) {
  .payment-container {
    padding: 15px;
  }

  .form-submit-btn {
    font-size: 1rem;
    padding: 12px;
  }

  .order-summary h2 {
    font-size: 1.8rem;
  }

  .form-group {
    gap: 4px;
    /* flex-direction: column; */
  }

  .plan-btn {
    width: 100%;
    margin-top: 10px;
  }
}